import { Home } from "./pages/index";
import { Footer } from "./components/index";

function App() {
  return (
    <div>
      <Home />
      <Footer />
    </div>
  );
}

export default App;
